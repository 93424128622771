import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@xo-union/tk-component-buttons';
import { Field } from '@xo-union/tk-component-fields';
import { FormRow, FormColumn, FormBlockColumn } from '@xo-union/tk-component-form-grid';

// Text fields
import AutoCompleteLocation from '../AutoCompleteLocation';
import SmartField from '../SmartField';

// Dropdowns
import states from '../../utils/us-states';
import countries from '../../utils/countries';
import Dropdown from '../Dropdown';

import { isInternationalPhone } from '../../utils/strings';
import { validPhone, validEmail } from '../../utils/validators';
import styles from './ContactInfoForm.scss';

export default class ContactInfoForm extends Component {
  static propTypes = {
    onReview: PropTypes.func.isRequired,
    onFieldChange: PropTypes.func.isRequired,
    onAddressAutocomplete: PropTypes.func.isRequired,
    partyMembers: PropTypes.arrayOf(PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired
    })).isRequired,
    street1: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    zip: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    country: PropTypes.string,
    street2: PropTypes.string,
    obfuscatedPhone: PropTypes.string,
    obfuscatedEmail: PropTypes.string,
  };

  fieldsToValidate = () => {
    const {partyMembers, street1, city, state, zip, phone, email} = this.props;
    return {
      leaderFirstName: partyMembers[0].firstName,
      leaderLastName: partyMembers[0].lastName,
      street1,
      city,
      state,
      zip,
      phone,
      email
    };
  }

  validForm = () => {
    const { phone, email, ...fieldsToValidate} = this.fieldsToValidate();
    const { obfuscatedPhone, obfuscatedEmail } = this.props

    return (
      Object.values(fieldsToValidate).every((fieldValue) => fieldValue && fieldValue.length > 0) &&
      (phone === obfuscatedPhone || validPhone(phone)) &&
      (email === obfuscatedEmail || validEmail(email))
    );
  }

  isUS = () => {
    const {country} = this.props;
    return (country === 'US' || country === "")
  }

  render() {
    const {
      validateForm,
      onFieldChange,
      onAddressAutocomplete,
      onReview,
       ...partyInfoProps
    } = this.props;
    return (
      <Fragment>
        {partyInfoProps.partyMembers.map((member, idx) => {
          const NameField = idx === 0 ? SmartField : Field

          return (
            <FormRow key={`form_row_${idx}`}>
              <FormColumn
                xs="12"
                md
              >
                <NameField
                  name="firstName"
                  label={"First name" +  (idx === 0 ? "*" : "")}
                  value={member.firstName}
                  onChange={onFieldChange}
                  data-idx={idx}
                  subText={idx === 0 ? "Please enter a first name." : ""}
                />
              </FormColumn>
              <FormColumn
                xs="12"
                md
              >
                <NameField
                  name="lastName"
                  label={"Last name" + (idx === 0 ? "*" : "")}
                  value={member.lastName}
                  onChange={onFieldChange}
                  data-idx={idx}
                  subText={idx === 0 ? "Please enter a last name." : ""}
                />
              </FormColumn>
            </FormRow>
          )
        })}
        <FormBlockColumn>
          <AutoCompleteLocation
            name="street1"
            label="Address line 1*"
            subText={"Please enter a mailing address."}
            onDropdownSelect={onAddressAutocomplete}
            onChange={onFieldChange}
            value={partyInfoProps.street1}
          />
        </FormBlockColumn>
        <FormBlockColumn>
          <Field
            name="street2"
            label="Address line 2"
            value={partyInfoProps.street2}
            onChange={onFieldChange}
          />
        </FormBlockColumn>
        <FormBlockColumn>
          <SmartField
            name="city"
            label="City*"
            onChange={onFieldChange}
            value={partyInfoProps.city}
            subText={"Please enter a city."}
          />
        </FormBlockColumn>

        <FormRow>
          <FormColumn
            xs="12"
            md
          >
            <SmartField
              name="zip"
              label="ZIP code*"
              onChange={onFieldChange}
              value={partyInfoProps.zip}
              subText={"Please enter a zip code."}
            />
          </FormColumn>
          <FormColumn
            xs="12"
            md
          >
            {
              this.isUS() ?
              <Dropdown
                name="state"
                label="State*"
                options={states}
                value={partyInfoProps.state}
                onDropdownChange={onFieldChange}
              />
              :
              <SmartField
                name="state"
                label="State*"
                onChange={onFieldChange}
                value={partyInfoProps.state}
                subText={"Please enter a state."}
              />
            }
          </FormColumn>
        </FormRow>
        <FormBlockColumn>
          <Dropdown
            name="country"
            label="Country"
            options={countries}
            value={partyInfoProps.country}
            onDropdownChange={onFieldChange}
          />
        </FormBlockColumn>
        <FormBlockColumn>
          <SmartField
            type="tel"
            name="phone"
            label="Phone number*"
            validator={validPhone}
            maxLength={isInternationalPhone(partyInfoProps.phone) ? null : 12}
            onChange={onFieldChange}
            value={partyInfoProps.phone}
            subText={"Please enter a valid phone."}
          />
        </FormBlockColumn>
        <FormBlockColumn>
          <SmartField
            name="email"
            type="email"
            label="Email address*"
            validator={validEmail}
            onChange={onFieldChange}
            value={partyInfoProps.email}
            subText={"Please enter a valid email."}
          />
        </FormBlockColumn>
        <div className={styles.nextButton}>
          <Button
            onClick={onReview}
            disabled={!this.validForm()}
          >
            Review
          </Button>
        </div>
      </Fragment>
    )
  }
}
