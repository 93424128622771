import React from 'react';
import styles from './DisclaimerText.scss';

export default function DisclaimerText(props) {
  return (
    <div className={styles.text}>
     {props.children}
    </div>
  )
}
