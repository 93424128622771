import {detectPlatform} from './detect-platform';

export const track = (properties = {}, ...rest) => {
  const event =  "Address Collection Interaction";
  const platform = detectPlatform();
  const amendedProperties = Object.assign(
    { 
      platform: `${platform} web`, 
      product: 'Guest Services', 
    },
     properties
  );

  if (window.analytics) {
    window.analytics.track(event, amendedProperties, ...rest);
  }
}
