import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Link } from '@xo-union/tk-component-buttons';
import { track } from '../../utils/analytics';
import Card from '../Card';
import styles from './ConfirmationCard.scss';
import { H2 } from '@xo-union/tk-ui-typography';

export default class ConfirmationCard extends Component {
  static propTypes = {
    coupleName: PropTypes.string.isRequired
  };

  render() {
    const {coupleName} = this.props;
    return (
      <Card>
        <div className={styles.confirmationCard}>
          <div className={styles.title}> 
            <H2>
              You're all set!
            </H2>
          </div>
          <div className={styles.confirmationText}> 
            {coupleName ? (
              <>{coupleName} appreciate you.</>
            ) : (
              <>We appreciate you.</>
            )}
          </div>
          
          <Link
            size="md"
            href="https://theknot.com"
            onClick={ () =>track({action: "click to theknot.com"}) }
          >
            Learn about The Knot
          </Link>
          
        </div>
      </Card>
    )
  }
}
