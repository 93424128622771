import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import App from './glm-address-collection';

export default class AppRouter extends React.Component {
  componentDidMount() {
    if (window.analytics) {
      window.analytics.page()
    }
  }

  render() {
    return (
      <Router>
        <Route path="/ac/:weddingSlug/:householdId?" component={App} />
      </Router>
    );
  }
}
