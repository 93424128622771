import countries, { getCountryCodeByString }  from './countries';
import { getStateAbbreviationByString } from './us-states';
import { safeTrim } from './strings.js'

function countryNameLookup(abbreviation) {
  const country = countries.find(c => c.abbreviation === abbreviation);
  return country ? country.name : '';
}

function countryAbbreviationLookup(name) {
  const country = countries.find(c => c.name === name);
  return country ? country.abbreviation : '';
}

function fullAddressString(addressObject) {
  const {
    street1,
    street2,
    city,
    zip,
    state
  } = addressObject;
  return [street1,
    street2,
    city,
    zip,
    state].filter(Boolean).join(", ");
}

function sanitizedAddress(addressObject) {
  const {street1, street2, city, zip, state, country, fullAddress} = addressObject;
  if (fullAddressString(addressObject).length === 0) return undefined;

  return {
    street1: safeTrim(street1) || undefined,
    street2: safeTrim(street2) || undefined,
    city: safeTrim(city) || undefined,
    zip: safeTrim(zip) || undefined,
    state: getStateAbbreviationByString(safeTrim(state)) || undefined,
    country: getCountryCodeByString(safeTrim(country)) || undefined,
    fullAddress: safeTrim(fullAddress) || undefined
  };
}

export { countryNameLookup, countryAbbreviationLookup, sanitizedAddress };
