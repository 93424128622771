import React, {Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from '@xo-union/tk-component-fields';

export default class SmartField extends Component {
  static propTypes = {
    validator: PropTypes.func
  }

  state = {
    validateField: false
  }

  validField = (value) => {
    const { validateField } = this.state;
    const { validator } = this.props;
    if (!validateField) return !validateField;
    if (validator) return validator(value);
    return value.length > 0;
  }

  fieldState = (fieldValue) => {
    if (this.validField(fieldValue)) return 'neutral';
    return 'invalid';
  }

  validationText = () => {
    const {subText, value } = this.props;
    return this.validField(value) ? undefined : subText
  }

  onBlurHandler = () => {
    this.setState({validateField: true})
  }

  render() {
   const {subText, validator, innerRef, ...fieldProps} = this.props;
   return ( 
    <Field
      ref={innerRef}
      onBlur={this.onBlurHandler}
      state={this.fieldState(fieldProps.value)}
      subText={this.validationText()}
      {
        ...fieldProps
      }
    />
  )};
}
