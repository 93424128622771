import React, { Component } from 'react';
import axios from 'axios';
import '@xo-union/tk-ui-essentials';
import Layout from '@xo-union/tk-component-layout';
import { DisplayButton } from '@xo-union/tk-ui-links';
import get from 'lodash.get';
import GLMGuestHeader from './components/GLMGuestHeader';
import ContactInfoForm from './components/ContactInfoForm';
import Card from './components/Card';
import ReviewCard from './components/ReviewCard';
import { sanitizedAddress } from './utils/address-utils';
import ConfirmationCard from './components/ConfirmationCard';
import DisclaimerText from './components/DisclaimerText';
import { formatPhone } from './utils/strings';
import { track } from './utils/analytics';
import { getStateAbbreviationByString } from './utils/us-states';
import { getCountryCodeByString} from './utils/countries';
import styles from './index.scss';

export default class GLMAddressCollection extends Component {
  // step 1 = initial form
  // step 2 = review card
  // step 3 = confirmation card

  initialState = {
    step: 1,
    userFirstName: "",
    fianceFirstName: "",
    weddingId: "",
    partyMembers: [{
      firstName: "",
      lastName: ""
    },
    {
      firstName: "",
      lastName: ""
    }],
    street1: "",
    street2: "",
    zip: "",
    city: "",
    state: "",
    country: "",
    phone: "",
    email: "",
  }

  constructor(props) {
    super(props)
    this.state = this.initialState;
  }

  reset = () => {
    this.setState(this.initialState);
  }

  getWeddingInfo = async () => {
    const weddingResp = await axios.get(this.gdsWeddingDetailsEndpoint())
    if (!weddingResp.data){
      throw new Error("Please check if this is the correct URL!");
    }
    const {first_name, fiance_first_name, wedding_uuid, state, slug} = weddingResp.data;
    this.setState({
      userFirstName: first_name,
      fianceFirstName: fiance_first_name,
      weddingId: wedding_uuid,
      wwsState: state,
      wwsSlug: slug
    })
  }

  getHouseholdInfo = async (householdId) => {
    const { GDS_API_KEY } = process.env
    const householdResp = await axios.get(this.gdsHouseholdEndpoint(householdId), { headers: { 'X-API-KEY': GDS_API_KEY }})
    if (!householdResp.data){
      throw new Error("Please check if this is the correct URL!");
    }

    const { people, address } = householdResp.data
    this.setState({
      partyMembers: people,
      phone: people[0].phone,
      obfuscatedPhone: people[0].phone,
      email: people[0].email,
      obfuscatedEmail: people[0].email,
      street1: get(address, ['street1']) || '',
      street2: get(address, ['street2']) || '',
      zip: get(address, ['zip']) || '',
      city: get(address, ['city']) || '',
      state: get(address, ['state']) || '',
      country: get(address, ['country']) || '',
    })
  }

  async componentDidMount() {
    try {
      await this.getWeddingInfo()
      this.setTitle()

      const { householdId } = this.props.match.params
      if (householdId) {
        await this.getHouseholdInfo(householdId)
      }
    } catch(error) {
      document.location.replace(process.env.REACT_APP_NOT_FOUND_URL);
    }
  }

  onReview = () => {
    track({action: "review info"});
    this.setStep(2);
  }

  setStep = (step) => {
    this.setState({step: step});
  }

  onEditInfo = () => {
    track({action: "edit"})
    this.setStep(1);
  }

  getHouseholdParams = (type) => {
    const {weddingId, partyMembers, phone, email} = this.state;
    return {
      address: this.getAddress(),
      weddingId: weddingId,
      people: partyMembers
        .filter(({ firstName, lastName }) => firstName && lastName)
        .map(({ firstName, lastName, id, invitations }, idx) => {
          const params = {
            firstName,
            lastName,
            id,
            invitations,
            phone: phone || undefined,
            email: email || undefined,
            isLeader: idx === 0
          }
          if (type === 'patch' && id) {
            params.id = id
          }

          return params
        })
    }
  }

  getAddress = () => {
    const {street1, street2, zip, city, state, country} = this.state;
    const address = {street1, street2, zip, city, state, country};
    return sanitizedAddress(address);
  }

  gdsHouseholdEndpoint = (householdId) => {
    const { REACT_APP_GDS_API_ENDPOINT } = process.env;
    const {weddingId} = this.state;
    const suffix = householdId ? `/${householdId}` : ''
    return `${REACT_APP_GDS_API_ENDPOINT}/guest/${weddingId}/households${suffix}`
  }

  gdsWeddingDetailsEndpoint = () => {
    const { REACT_APP_GS_API_ENDPOINT } = process.env;
    const { weddingSlug } = this.props.match.params;
    return `${REACT_APP_GS_API_ENDPOINT}wedding-details?wedding_slug=${weddingSlug}`;
  }

  toggleReviewState = () => {
    this.setState((state) => (
      {reviewInfo: !state.reviewInfo}
    ));
  }

  isUS = () => {
    const {country} = this.state;
    return (country === 'US' || country === "")
  }

  onFieldChange = (e) => {
    var {value, name, dataset } = e.target;

    if (name === 'phone') {
      value = formatPhone(value);
    } else if (this.isUS() && name === 'state' ) {
      value = getStateAbbreviationByString(value);
    } else if (name === 'country') {
      value = getCountryCodeByString(value);
    } else if (name === 'firstName' || name === 'lastName') {
      const updatedPartyMembers = [...this.state.partyMembers]
      const idx = dataset.idx
      updatedPartyMembers[idx][name] = value

      return this.setState({
        ...this.state,
        partyMembers: updatedPartyMembers
      })
    }

    this.setState({[name]: value});
  }

  onAddressAutocomplete = (addressObject) => {
    if (addressObject) {
      this.setState(addressObject)
    }
  }

  checkForObfuscation = (householdParams) => {
    const { obfuscatedPhone, obfuscatedEmail } = this.state

    householdParams.people.forEach(person => {
      if (person.email === obfuscatedEmail) {
        delete person.email
      }
      if (person.phone === obfuscatedPhone) {
        delete person.phone
      }
    })

    return householdParams
  }

  onSubmit = async () => {
    const { householdId } = this.props.match.params

    if (householdId) {
      const householdParams = this.getHouseholdParams('patch')
      const processedParams = this.checkForObfuscation(householdParams)
      delete processedParams.weddingId

      await axios.patch(
        this.gdsHouseholdEndpoint(householdId),
        processedParams
      )
    } else {
      await axios.post(
        this.gdsHouseholdEndpoint(),
        this.getHouseholdParams()
      )
    }

    track({action: "address submitted"});
    this.setStep(3);
  }

  setTitle = () => {
    if (this.coupleNamesExists()){
      document.title = `${this.coupleName()} would like your address!`;
    } else {
      document.title = `We would like your address!`;
    }
  }

  coupleNamesExists = () => {
    const {userFirstName, fianceFirstName} = this.state;
    return userFirstName.length > 0 && fianceFirstName.length > 0
  }

  coupleName = (symbol) => {
    if (!this.coupleNamesExists()) return null;

    const {userFirstName, fianceFirstName} = this.state;
    const andType = symbol || "and";
    return `${userFirstName} ${andType} ${fianceFirstName}`;
  }

  render() {
    const {step, errorMessage, userFirstName, fianceFirstName, weddingId, ...partyProps} = this.state;
    return (
      <Layout
        header={
          <GLMGuestHeader
            coupleName={this.coupleName('&')}
            showSubCopy={step === 1}
            step={step}
          />
        }
      >
          <div className={styles.cardsLayout}>
            {step === 1 &&
              <Card 
                title=" " 
                moreInfo="*Required"
              >
                <ContactInfoForm
                  onReview={this.onReview}
                  onFieldChange={this.onFieldChange}
                  onAddressAutocomplete={this.onAddressAutocomplete}
                  {...partyProps}
                />
                <DisclaimerText>
                Your info won't be shared with anyone else.&nbsp;
                { /* eslint-disable-next-line no-undef*/}
                  <DisplayButton color='secondary' onClick={ () => globalThis.UnionConsentManagement.togglePreferencesModal()}>
                    More on privacy
                  </DisplayButton>
                </DisclaimerText>
              </Card>
            }
            { 
              step === 2 &&
              <ReviewCard
                coupleName={this.coupleName()}
                onSubmit={this.onSubmit}
                onEditInfo={this.onEditInfo}
                {...partyProps}
              />
            }
            {
              step === 3 &&
              <ConfirmationCard
                coupleName={this.coupleName()}
              />
            }
          </div>
      </Layout>
    );
  }
}
