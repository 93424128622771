import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Select, Option } from '@xo-union/tk-component-fields';

export default class Dropdown extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    onDropdownChange: PropTypes.func.isRequired
  }

  onDropdownSelect = (e) => {
    const {onDropdownChange, name} = this.props
    const updatedValue = {
      target: {
        name: name,
        value: e.option.value
      }
    };
    return onDropdownChange(updatedValue);
  }

  dropdownOptions = () => {
    const {options} = this.props;
    return options.map((el) => {
      return <Option
        key={el.name}
        value={el.abbreviation}
        label={el.name}
      />;
    });
  }

  render() {
    const {list, onDropdownChange, ...dropdownProps} = this.props;
    return (
      <Select
        onChange={this.onDropdownSelect}
        {...dropdownProps}
      >
        {
          this.dropdownOptions()
        }
      </Select>
    )
  }
}
