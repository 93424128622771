import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Button } from '@xo-union/tk-component-buttons';
import { DisplayButton } from '@xo-union/tk-ui-links';
import { H2 } from '@xo-union/tk-ui-typography';
import ReviewInfoSection from './ReviewInfoSection';
import DisclaimerText from '../DisclaimerText';
import Card from '../Card';
import styles from './ReviewCard.scss';

export default class ReviewCard extends Component {
  static propTypes = {
    coupleName: PropTypes.string.isRequired,
    onEditInfo: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    street1: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    zip: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    country: PropTypes.string,
    street2: PropTypes.string,
  };

  isIndividual = () => {
    const { partyMembers } = this.props;
    // individual if party has 1 member OR if either name is blank for second member
    return partyMembers.length === 1 || (partyMembers[1].firstName === '' || partyMembers[1].lastName === '');
  }

  render() {
    const {
      onEditInfo,
      onSubmit,
      coupleName,
      partyMembers,
      street1,
      street2,
      phone,
      email,
      city,
      state,
      zip,
      country
    } = this.props;

    const guestNameTitle = (this.isIndividual()) ? 'Guest name' : 'Guest names';
    return (
      <Card>
        <div className={styles.container}>
          <div className={styles.title}>
            <H2>Is everything correct?</H2>
            </div>
            {coupleName ? (
              <div>Let's make sure before you send it to {this.props.coupleName}.</div>
            ) : (
              <div>Let's make sure before you send it to us.</div>
            )}

          <ReviewInfoSection
            name={guestNameTitle}
          >
            {partyMembers
              .filter(({ firstName, lastName }) => firstName && lastName)
              .map(({ firstName, lastName }) =>
                <div>{`${firstName} ${lastName}`}</div>
              )
            }
          </ReviewInfoSection>
          <ReviewInfoSection
            name={'Phone number'}
          >
            {
              phone
            }
          </ReviewInfoSection>
          <ReviewInfoSection
            name={'Email address'}
          >
            {email}
          </ReviewInfoSection>
          <ReviewInfoSection
            name={'Street address'}
          >
            <div>
              {street1}
            </div>
            <div>
              {street2}
            </div>
            <div>
              {city}, {state} {zip}
            </div>
            <div>
              {country}
            </div>
          </ReviewInfoSection>
          <Button
            size="md"
            color="secondary"
            onClick={onEditInfo}
            className={styles.button}
          >
            Edit
          </Button>
          <Button
            size="md"
            onClick={onSubmit}
            className={styles.button}
          >
            Send
          </Button>
          <DisclaimerText>
              Your info won't be shared with anyone else.&nbsp;
              <DisplayButton color='secondary' onClick={ () => globalThis.UnionConsentManagement.togglePreferencesModal()}>More on privacy</DisplayButton>{ // eslint-disable-line no-undef
              }
          </DisclaimerText>
        </div>
      </Card>
    )
  }
}
