import React, {Component, Fragment, createRef } from 'react';
import {objectifyAddressComponents, translateAddressObject} from '../../utils/google-helper';
import SmartField from '../SmartField';

export default class AutoCompleteLocation extends Component {
  autoCompleteRef = createRef();

  componentDidMount() {
    const libraryScript = this.existingLibraryScript;
    if (this.libraryHasLoaded) {
      this.initAutocomplete();
    } else if (libraryScript) {
      libraryScript.addEventListener('load', () => { this.initAutocomplete(); });
    } else {
      this.addAutocompleteLibrary();
    }
  }

  initAutocomplete() {
    this.autocomplete = new google.maps.places.Autocomplete(this.autoCompleteRef.current, { types:['address'] }); // eslint-disable-line no-undef
    this.autocomplete.setFields(['name', 'formatted_address', 'address_components', 'place_id']);
    this.autocomplete.addListener('place_changed', this.getPlace);
  }

  getPlace = () => {
    const place = this.autocomplete.getPlace();
    const addressObject = objectifyAddressComponents(place.address_components);
    this.props.onDropdownSelect(translateAddressObject(addressObject));
  }

  get libraryHasLoaded() { // eslint-disable-line class-methods-use-this
    return typeof google !== 'undefined';
  }

  get existingLibraryScript() { // eslint-disable-line class-methods-use-this
    return document.getElementById('location-autocomplete-library') || document.querySelectorAll('script[src*="maps.googleapis.com/maps/api/js"]')[0];
  }

  addAutocompleteLibrary = () => {
    const scriptTag = document.createElement('script');
    scriptTag.type = 'text/javascript';
    scriptTag.id = 'location-autocomplete-library';
    scriptTag.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places&v=3.40`;
    (document.head || document.body).appendChild(scriptTag);
    scriptTag.addEventListener('load', () => { this.initAutocomplete(); });
  }

render() {
    const {
      onDropdownSelect, // eslint-disable-line no-unused-vars
      onAddressAutocomplete,
      onChange,
      ...props
    } = this.props;

    return (
      <Fragment>
        <SmartField
          type="text'"
          onChange={onChange}
          innerRef={this.autoCompleteRef}
          {...props}
        />
      </Fragment>
    );
  }
}
