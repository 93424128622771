export const safeTrim = (val) => {
  const trimmed = val === undefined ? '' : val.trim();
  return trimmed;
};

export const isInternationalPhone = number => /^\+/.test(number);

export const usPhoneFormat = (digits) => {
  if (digits.length > 3 && digits.length <= 6) {
    return digits.replace(/^(\d{3})(\d{1,3})$/, '$1-$2');
  } else if (digits.length > 6 && digits.length <= 10) {
    return digits.replace(/^(\d{3})(\d{3})(\d{1,4})$/, '$1-$2-$3');
  }
  return digits;
};

export const formatPhone = (fieldValue = '') => {
  const digits = fieldValue.replace(/\D/g, '');
  if (isInternationalPhone(fieldValue)) return `+${digits}`;
  return usPhoneFormat(digits);
};

export const usZipFormat = (digits) => {
  if (digits.length > 5 && digits.length <= 10) {
    return digits.replace(/^(\d{5})(\d{1,5})$/, '$1-$2');
  }
  return digits;
};

export const formatZip = (fieldValue = '', isUS = true) => (isUS ? usZipFormat(fieldValue.replace(/\D/g, '')) : fieldValue);
