import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BrandLogo from '@xo-union/component-brand-logo';
import styles from './GLMGuestHeader.scss';
import { H2 } from '@xo-union/tk-ui-typography';
export default class GLMGuestHeader extends Component {
  static propTypes = {
    coupleName: PropTypes.string,
    showSubCopy: PropTypes.bool,
    step: PropTypes.number,
  };

  render() {
    const { coupleName, showSubCopy, step } = this.props;
    return (
      <div className={`${styles.glmHeader} ${step===3 ? styles.confirmationStep : ''}`}>
        <div className={styles.logo}>
          <BrandLogo
            name='theknot'
            color='primary'
            size="lg"
          />
        </div>
        {
          step === 1 &&
          <div className={styles.glmTitle}>
            {coupleName ? (
              <H2>{coupleName} <br/> would like your address</H2>
            ) : (
              <H2>We're engaged and would like your address</H2>
            )}
          </div>
        }
        { 
          showSubCopy &&
          <div className={styles.pageDescription}>
            It's almost party time and you're about to find out more.
          </div>
        }
      </div>
    );
  }
}
