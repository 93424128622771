import { countryCodeLookup } from './countries';

export const objectifyAddressComponents = (addressComponents = []) => {
  const objectifiedAddressComponents = {};
  addressComponents.forEach((component) => {
    component.types.forEach((componentType) => {
      if (componentType === 'administrative_area_level_1') {
        objectifiedAddressComponents[componentType] = component.short_name;
      } else {
        objectifiedAddressComponents[componentType] = component.long_name;
      }
    });
  });
  return objectifiedAddressComponents;
}

export const translateAddressObject = (addressComponents) => {
  const {
    street_number,
    route,
    locality,
    sublocality,
    administrative_area_level_1,
    postal_code,
    country,
  } = addressComponents;
  const address = {
    street1: street_number ? `${street_number} ${route}` : route, // eslint-disable-line camelcase
    city: locality || sublocality,
    state: administrative_area_level_1,
    zip: postal_code,
    country: countryCodeLookup(country)
  };
  Object.keys(address).forEach(key => address[key] === undefined && delete address[key])
  return address;
}
