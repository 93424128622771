import React from 'react';
import PropTypes from 'prop-types';
import styles from './Card.scss';
import { H4, Caption } from '@xo-union/tk-ui-typography';
export default class GLMGCard extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    moreInfo: PropTypes.string
  }

  render() {
    const {title, moreInfo, children} = this.props;
    return (
      <div className={styles.card} >
        {
          title &&
          <div 
            className={styles.cardTitle}
          >
            <H4>{ title}</H4>
            {
              moreInfo &&
              <span 
                className={styles.moreInfo}
              > 
                <Caption>{ moreInfo }</Caption>
              </span>
            }
          </div>
        }
        { children}
      </div>
    );
  }
}
