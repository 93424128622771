import React from "react";
import styles from "./ReviewCard.scss";

export default function ReviewInfoSection(props) {
  return (
    <div className={styles.sectionContainer}>
      <div className={styles.sectionName}>
        {props.name}
      </div>
      <div className={styles.sectionValue}>
        {props.children}
      </div>
    </div>
  )
}
