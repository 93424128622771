import { isInternationalPhone } from './strings';

export const validUSPhone = phone => /^(?!0|1)\d{3}-\d{3}-\d{4}$/.test(phone)

export const validInternationalPhone = phone => /^\+\d+$/.test(phone)

export const validEmail = (email = '') => {
  const regex = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
  return regex.test(email);
}

export const validPhone = (phone) => {
  if (isInternationalPhone(phone)) {
    return validInternationalPhone(phone);
  }
  return validUSPhone(phone)
}
